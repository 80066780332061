let Custom;

export default Custom;

const spyScrolling = () => {
  const sections = document.querySelectorAll('.hero-bg');

  window.onscroll = () => {
    const scrollPos = document.documentElement.scrollTop + 95 || document.body.scrollTop + 95; // add Headerheight

    for (let s in sections)
      if (sections.hasOwnProperty(s) && sections[s].offsetTop <= scrollPos) {
        const id = sections[s].id;
        document.querySelector('.active').classList.remove('active');
        document.querySelector(`a[href*=${id}]`).parentNode.classList.add('active');
      }
  }
}

// makeNavLinksSmooth();
spyScrolling();

window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */
  var btnnav = document.querySelector('.js-togglemenu');
  var mobilenav = document.querySelector('.js-navwrapper');
  var body = document.querySelector('.js-body');

  btnnav.onclick = function () {
    btnnav.classList.toggle('toggle');
    mobilenav.classList.toggle('active');
    body.classList.toggle('active');
  }

  /* logoanimation
  -------------------------------------------------- */
  /* initiate animation */
  lottie.loadAnimation({
    container: document.getElementById('logo'),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: 'resources/anim/logoanimation.json'
  });

  /* test viewportposition */
  var isInViewport = function (elem) {
    var distance = elem.getBoundingClientRect();
    return (
      distance.top >= 0 &&
      distance.left >= 0 &&
      distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  var findMe = document.querySelector('#logo');

  if (findMe) {

    window.addEventListener('scroll', function (event) {
      if (isInViewport(findMe)) {
        lottie.play();
      }
    }, false);

  }


  /* scroll top
    -------------------------------------------------- */
  document.querySelector(".btn-scrolltop").addEventListener("click", (function () {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }));

  var myID = document.getElementById("scrollTop");

  var myScrollFunc = function () {
    var y = window.scrollY;
    if (y >= 2048) {
      myID.className = "btn btn-scrolltop show"
    } else {
      myID.className = "btn btn-scrolltop hide"
    }
  };

  window.addEventListener("scroll", myScrollFunc);

};
